<template>
    <div>
      <base-page 
      title="Paybill Configuration"
      call-to-action-text="New Configurations"
      call-to-action-permission="silo.resources.store"
      :breadcrumbs="breadcrumbs"
      @call-to-action="dialog = true">
        <template #content>
          <configuration-list @edit="edit" />
        </template>
      </base-page>

      <configuration-form
        v-if="dialog"
        title="Create new configuration"
        :dialog="dialog"
        :configurationsData="configurationsData"
        @close="close()"
      />
    </div>
  </template>
  
  <script>
  export default {
    components: {
      'configuration-list': () => import('@/components/paybill/ConfigurationList.vue'),
      'configuration-form': () => import('@/components/paybill/ConfigurationForm.vue'),
    },
  
    data() {
      return {
        breadcrumbs: [{ text: 'Configurations', disabled: true }],
        dialog: false,
        configurationsData: null,
      }
    },
  
    methods: {
      close() {
        this.dialog = false
        this.configurationsData = null
      },

      edit (configurationsData) {
      this.dialog = true
      this.configurationsData = configurationsData
    }
    },
  }
  </script>
  